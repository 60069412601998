// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import KnowledgeArticle from 'components/Contentful/KnowledgeArticle'
import SideNavigation from 'components/SideNavigation'
import PageNavigation from 'components/PageNavigation'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Funktionsweise Wärmepumpe"
        meta={[
          { name: 'description', content: 'Funktionsweise einer Wärmepumpe. Die Wärmepumpe funktioniert wie ein Kühlschrank – nur umgekehrt. Sie entzieht Energie aus der Umgebung (z.B. Luft) und gibt sie an das Haus ab. Erfahren Sie mehr - www.heizungsmacher.ch.' },
          { name: 'keywords', content: '' },
        ]}
      >
        
      </Helmet>
      
      <PageNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "3P9prSaMlzdADoC8f1gPT7",
        "updatedAt": "2021-02-11T13:38:26.956Z",
        "label": "Fördergelder",
        "page": {
            "slug": "foerdergelder"
        }
    },
    {
        "type": "link",
        "id": "1xligmPvFk1S9Z6xdQzjAE",
        "updatedAt": "2020-03-27T13:38:18.329Z",
        "label": "Die Wärmepumpe einfach erklärt",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert"
        }
    }
]} /><div className='section__nav section--padded'><SideNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "3YsoMo1NVNMD9R5EUCcPZY",
        "updatedAt": "2020-03-27T12:09:36.532Z",
        "label": "Eine Wärmepumpe ist",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert/eine-waermepumpe-ist"
        }
    },
    {
        "type": "link",
        "id": "4eeVTOLecGuLIeZOm4LSQ",
        "updatedAt": "2020-03-27T14:19:22.162Z",
        "label": "Funktionsweise Wärmepumpe",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert/funktionsweise-einer-waermepumpe"
        }
    },
    {
        "type": "link",
        "id": "mlApPFnIU8bx7k8iiIeJs",
        "updatedAt": "2020-03-27T14:53:37.132Z",
        "label": "Häufig gestellte Fragen - FAQ",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert/haeufig-gestellte-fragen-faq"
        }
    }
]} /><div
          className='bg__flex--white'
          key='2HFzip4XAy6gBVzBT7Tn1X-4kx53i0XQEB0sh0Mpdjr0t'
          id=''
        >
          <KnowledgeArticle
            {...{
    "type": "knowledgeArticle",
    "id": "4kx53i0XQEB0sh0Mpdjr0t",
    "updatedAt": "2020-04-06T14:07:08.986Z",
    "title": "Funktionsweise  einer Wärmepumpe",
    "keyword": "Funktionsweise",
    "articleType": "Anleitung",
    "description": "Die Wärmepumpe funktioniert wie ein Kühlschrank – nur umgekehrt. Sie entzieht Energie aus der Umgebung (z.B. Luft) und gibt sie an das Haus ab.",
    "content": [
        {
            "type": "howToStep",
            "id": "5Jcq3sCzdrWmgp020xDaQD",
            "updatedAt": "2021-02-11T08:37:05.666Z",
            "title": "Verdunstung",
            "description": "<p><strong>Das Kältemittel im Verdampfer nimmt Wärmeenergie aus der Umgebung auf,</strong> erwärmt sich und ändert seinen Aggregatzustand von flüssig (kalt) zu gasförmig (warm). Dieser Prozess ist mit dem Verdampfen von Wasser vergleichbar, mit dem Unterschied, dass das Temperaturniveau deutlich geringer ist.</p>\n",
            "image": {
                "description": "Grafik zur Erklärung der Funktionsweise einer Wärmepumpe",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/6DbT7ZXZduEwUuI24qISaY/28d1b37aa90e98863911491caa01c5ca/Referenzbild---wie-funktioniert-eine-w__rmepumpe.png"
            }
        },
        {
            "type": "howToStep",
            "id": "19gQTrLiJPk2zAVjwL2rKv",
            "updatedAt": "2021-02-11T08:37:21.806Z",
            "title": "Verdichtung",
            "description": "<p>Im nächsten Schritt verdichtet der elektrische Kompressor das gasförmige Kältemittel. <strong>Durch den Kompressionsvorgang erwärmt sich das Kältemittel,</strong> bis das im Heizsystem erforderliche Temperaturniveau erreicht wird.</p>\n"
        },
        {
            "type": "howToStep",
            "id": "74zR3L6hudYWgh1bXWuSU6",
            "updatedAt": "2021-02-11T08:37:40.630Z",
            "title": "Erhitzung",
            "description": "<p><strong>Im Wärmetauscher übergibt das Kältemittel seine Wärmeenergie an das Wasser im Heizungskreislauf des Gebäudes.</strong> Auf diese Weise gelingt es, die aus der Umgebung gewonnene Energie schliesslich zum Heizen oder zur Warmwasserbereitung zu verwenden. Bei der Wärmeübertragung wird dem Kältemittel Wärme entzogen und es geht vom gasförmigen wieder in den flüssigen Zustand über – es kondensiert.</p>\n"
        },
        {
            "type": "howToStep",
            "id": "1KVoZp2vxColqwUojLIDOm",
            "updatedAt": "2021-02-11T08:38:11.141Z",
            "title": "Abkühlung und Verflüssigung",
            "description": "<p>Bevor der Kreislauf im Verdampfer erneut beginnt, fliesst das Kältemittel durch das Expansionsventil. <strong>Dabei sinken Druck und Temperatur auf den Ausgangszustand und der Heizkreislauf beginnt wieder von vorne.</strong></p>\n"
        }
    ],
    "topic": {
        "type": "link",
        "id": "1xligmPvFk1S9Z6xdQzjAE",
        "updatedAt": "2020-03-27T13:38:18.329Z",
        "label": "Die Wärmepumpe einfach erklärt",
        "page": {
            "slug": "Die-Waermepumpe-einfach-erklaert"
        }
    },
    "previewImage": {
        "description": "Grafik zur Erklärung der Funktionsweise einer Wärmepumpe",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/6DbT7ZXZduEwUuI24qISaY/28d1b37aa90e98863911491caa01c5ca/Referenzbild---wie-funktioniert-eine-w__rmepumpe.png"
    },
    "previousArticle": "/Die-Waermepumpe-einfach-erklaert/eine-waermepumpe-ist",
    "nextArticle": "/Die-Waermepumpe-einfach-erklaert/haeufig-gestellte-fragen-faq"
}} location={props.location}
          />
        </div></div>
    </Layout>
  )
}

export default Page
